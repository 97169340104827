// Classes
import { BaseUser } from "./base-user.class";

export class Group {
    id: string = null;
    usaagoGroupName: string = null;
    usaagoUsers: BaseUser[] = null;
    usaagoPosition: number = null;

    constructor(groupShape?: Partial<Group>) {
        if (groupShape != null) {
            if (groupShape.id != null) {
                this.id = groupShape.id;
            }

            if (groupShape.usaagoGroupName != null) {
                this.usaagoGroupName = groupShape.usaagoGroupName;
            }

            if (groupShape.usaagoUsers != null) {
                this.usaagoUsers = groupShape.usaagoUsers.map((value) => new BaseUser(value));
            }

            if (groupShape.usaagoPosition != null) {
                this.usaagoPosition = groupShape.usaagoPosition;
            }
        }
    }
}
